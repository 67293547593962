import { createGlobalStyle } from "styled-components"
import { COLORS, DEVICES } from "src/constants"

const GlobalStyle = createGlobalStyle`
    html {
        --color-bg-accent: ${COLORS.background_blue};
        --color-bg-dark: ${COLORS.background_black};
        --color-bg1: ${COLORS.background_primary};
        --color-bg2: ${COLORS.background_secondary};
        --color-bttn1-bg: ${COLORS.button_primary_bg};
        --color-bttn1-bg-hover: ${COLORS.button_primary_bg_hover};
        --color-bttn2-bg: ${COLORS.button_secondary_bg};
        --color-bttn2-text: ${COLORS.button_secondary_text};
        --color-divider: ${COLORS.border_bottom};
        --color-step-connector: ${COLORS.step_connector};
        --color-step-num: ${COLORS.step_number};
        --color-text_footer: ${COLORS.text_footer};
        --color-text-light: ${COLORS.text_light};
        --color-text-hover: ${COLORS.text_onhover};
        --color-text-primary: ${COLORS.text_dark};
    }

    html {
        box-sizing: border-box;
    }

    *,
    *::before,
    *::after {
        box-sizing: inherit;
    }

    body {
        background-color: var(--color-bg-primary);
        font-family: 'Manrope', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        @media (orientation: portrait) and (min-width: 700px) and (max-width: 1024px) and (-webkit-min-device-pixel-ratio: 1.5) {
            font-size: 18px;
        }
    }

    body,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p,
    ol,
    ul {
        margin: 0;
        padding: 0;
    }

    h1 {
        font-size: 38px;
        font-weight: 600;
        line-height: 46px;
        @media ${DEVICES.laptopL} {
            font-size: 60px;
            line-height: 80px;
        }
    }

    h2 {
        font-size: 34px;
        font-weight: 600;
        line-height: 44px;
        @media ${DEVICES.laptopL} {
            font-size: 44px;
        }
    }

    a {
        color: inherit;
        text-decoration: none
    }

    p {
        font-size: 16px;
        line-height: 28px;
        @media ${DEVICES.laptopL} {
            font-size: 18px;
            line-height: 32px;
        }
    }

    button {
        cursor: pointer;
        font-family: 'Manrope', sans-serif;
        padding: 0;
    }

    ol,
    ul {
        list-style: none;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    span.m-right {
        margin-right: 20px;
    }
`

export default GlobalStyle
