export const COLORS = {
  background_black: "#18191f",
  background_blue: "#b3d7ec",
  background_primary: "white",
  background_secondary: "#f5f5f6",
  border_bottom: "#e5e9f2",
  button_primary_bg: "#e62012",
  button_primary_bg_hover: "#f2645a",
  button_secondary_bg: "#e6e7e7",
  button_secondary_text: "#525257",
  step_connector: "lightgrey",
  step_number: "#dcddde",
  text_dark: "#18191f",
  text_footer: "#525257",
  text_light: "white",
  text_onhover: "#ee3123",
}

const SIZE = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopM: "1200px",
  laptopL: "1440px",
  desktop: "1920px",
  desktopL: "2560px"
}

export const DEVICES = {
  mobileS: `(min-width: ${SIZE.mobileS})`,
  mobileM: `(min-width: ${SIZE.mobileM})`,
  mobileL: `(min-width: ${SIZE.mobileL})`,
  tablet: `(min-width: ${SIZE.tablet})`,
  laptop: `(min-width: ${SIZE.laptop})`,
  laptopM: `(min-width: ${SIZE.laptopM})`,
  laptopL: `(min-width: ${SIZE.laptopL})`,
  desktop: `(min-width: ${SIZE.desktop})`,
  desktopL: `(min-width: ${SIZE.desktopL})`,
}
