import React from "react"
import GlobalStyle from "assets/styles/globalStyles"

if (typeof window !== "undefined") {
  // eslint-disable-next-line global-require
  require("smooth-scroll")('a[href*="#"]', {
    offset: 72
  })
}

const MainLayout = ({ children }) => (
  <>
    <GlobalStyle />
    {children}
  </>
)

export default MainLayout
